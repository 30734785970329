jQuery.noConflict();
(function( $ ) {
    $(function() {
    //Document Ready STARTS

        //PLACEHOLDERS
       // $('input, textarea').placeholder();

        servicesMinHeight();
        imagesQuality();
        setHomeSliderHeight();
        adjustHPaddings();
        bindOpacity();
        bindTeamMore();
        bindTeamMember();
        bindFilterChanging(); //bind about page select show and change options
        if($('#filter').length> 0) {
            initIsotope(); //isotope
        }

        //mobile menu show/hide
        $('.the-mobile-menu').hide();
        $('.hamburger').on('click', function(e){
            e.preventDefault();
            $(this).toggleClass('is-active');
            $('.the-mobile-menu').slideToggle().toggleClass('active');
            $('.mobile-header').toggleClass('active');
        });


        //Swich page tabs on page reload
        if(window.location.hash) {
            var hash = window.location.hash;
            if ($(hash).length>0) {
                var link = window.location.href;
                setActiveLink(hash);
                $(hash).addClass('active');
                if($('body').hasClass('page-template-template-skeleton-php')) {
                    $('.skeleton-links a[href="'+hash+'"]').addClass('active');
                }
                else {
                    $('.content-links li a[href="'+hash+'"]').addClass('active');
                }

                scrollToEl(hash, 220);
            }
        }
        else {
            if($('ul.content').length>0) {
                if($('body').hasClass('page-template-template-skeleton-php')) {
                    $('.skeleton-links a:first').addClass('active');
                    var emptyImg = $('.content li:first-child').find('.sk-top-img.placeholder');
                    emptyImg.attr('src', emptyImg.data('src')).removeClass('placeholder');
                }
                else {
                    $('.content-links li:first-child a').addClass('active');

                }
                $('.content li:first-child').addClass('active');
            }
        }

        //changin services tabs in page
        $('a.content-link').on('click', function(e) {
            e.preventDefault();
            var target= $(this).attr('href');
            $('.content-links a').removeClass('active');
            $('.content > li').removeClass('active').hide();
            $(target).addClass('active');
            $(this).addClass('active');
            e.preventDefault();
            setActiveLink(target);
            history.pushState({}, '', this.href);
            $(target).slideToggle('slow', function() {
                if (getWindowWidth()<640) {
                    scrollToEl(target, 0);
                }
            });
        });

        $(window).on('hashchange', function(e){
            var target = window.location.hash;
            if ($(target).length>0) {
                setActiveLink(target);
                if($('body').hasClass('page-template-template-skeleton-php')) {
                    $('.skeleton-links a').removeClass('active');
                    $(".skeleton-links a[href='"+target+"']").addClass('active');
                }
                else {
                    $('.content-links a').removeClass('active');
                    $(".content-links a[href='"+target+"']").addClass('active');
                }

                $('.content > li').removeClass('active').hide();
                $(target).addClass('active');
                var emptyImg = $(target).find('.sk-top-img.placeholder');
                emptyImg.attr('src', emptyImg.data('src')).removeClass('placeholder');
                e.preventDefault();
                if($('.hamburger').hasClass('is-active')) {
                    $('.hamburger').trigger('click');
                }
                history.pushState({}, '', this.href);
                $(target).slideToggle('slow', function() {
                    if (getWindowWidth()<640) {
                        scrollToEl(target, 0);
                    }
                });
            }
        });

        function setActiveLink(hash) {
            $('.submenu li').removeClass('active');
            $(".submenu a[href$='"+hash+"']").parent().addClass('active');
        }

        $('.to-services').on('click', function() {
            scrollToEl('.content-links', 110);
        });

        $('.to-skeleton').on('click', function() {
            scrollToEl('.skeleton-links', 110);
        });

        //set services Min height on desktop
        function servicesMinHeight() {
            if($('ul.content-links').length>0) {
                $('.page-content').css({'min-height': 'auto'});
                $('services-holder').removeAttr('style');
                if (getWindowWidth()>640) {
                    var minHeight = $('.entry-links').outerHeight();
                    $('.page-content').css({'min-height': minHeight+'px'});
                    $('.services-holder').css({'min-height': minHeight+'px'});
                }
            }
        }

        $('.scroll-down').on('click', function(e) {
            e.preventDefault();
            scrollToEl('#content', 0);
        });

        //Window resize actions
        $(window).smartresize(function(){
            servicesMinHeight();
            imagesQuality();
            setHomeSliderHeight();
            adjustHPaddings();
        });

        //run functions on window resize
        $(window).scroll(function(){
            headerSrollStatus();
        });

        //Change header status on scroll
        function headerSrollStatus() {
            var scrollPos = $(window).scrollTop(),
                windowWidth = getWindowWidth();
            if (windowWidth>960 && scrollPos>20) {
                $('.header').addClass('scrolled')
            }
            else {
                $('.header').removeClass('scrolled')
            }
            if (windowWidth<960 && scrollPos>20) {
                $('.header').addClass('stick');
            }
            else {
                $('.header').removeClass('stick');
            }
        }

        function adjustHPaddings() {
            var slideHeight = $('.h-slide').outerHeight();
            $('.h-slide > .content-block').removeAttr('style');
            if(getWindowWidth()>960) {
                $('.h-slide > .content-block').each(function (index, value) {
                    var thisHeight = $(this).outerHeight();
                    var rowPadding = (slideHeight-thisHeight)/2;
                    $(this).css({'padding-top':  rowPadding-80});
                });
            }
            if(getWindowWidth()>400 && getWindowWidth()<960) {
                $('.h-slide > .content-block').each(function (index, value) {
                    var thisHeight = $(this).outerHeight();
                    var rowPadding = (slideHeight-thisHeight)/2;
                    $(this).css({'padding-top':  rowPadding-260});
                });
            }
        }

        /**
         * filter changing with custom select list
         */
        function bindFilterChanging() {

            $('#selected').on('click', function() {
                $('#select').toggle();
            });

            $('.option').on('click', function() {
                var tag = $(this).data('sel'),
                    text = $(this).text();
                $('.option').removeClass('active');
                $(this).addClass('active');
                $('#selected').data('sel', tag).text(text);
                $('#select').hide();
                $('#selected').trigger( "change" );
            });

            $('.clear-filter').on('click', function() {
                $('#selected').text('Filter By').data('sel', '');
                $('#selected').trigger( "change" );
            })
        }

        $(document).mouseup(function (e) {
            var container = $("#select");
            if (container.is(':visible')){
                $('#select').hide();
            }
        });

        /**
         * Isotope for tem mmebers filtering
         */
        function initIsotope() {

            var defaultOptions = {
                filter: "*",
                itemSelector: '.isotope-item',
                layoutMode: 'fitRows'
            };

            var $container = $('.isotope').isotope(defaultOptions);

            // filter functions
            var filterFns = {
                // show if number is greater than 50
                numberGreaterThan50: function() {
                    var number = $(this).find('.number').text();
                    return parseInt( number, 10 ) > 50;
                },
                // show if name ends with -ium
                ium: function() {
                    var name = $(this).find('.name').text();
                    return name.match( /ium$/ );
                }
            };

            $('#selected').on( 'change', function() {
                var filterValue = $('#selected').data('sel');
                if (filterValue != '') {
                    filterValue = '.'+filterValue;
                }
                // use filterFn if matches value
                filterValue = filterFns[ filterValue ] || filterValue;
                $container.isotope({ filter: filterValue });
            });

        }

        //Check if DOM has MAP
        if(($('#map').data('lat') != '') && ($('#map').data('lat') != undefined)) {
            initMap();
        }

        //init MAP with custom settings
        function initMap() {

            var $mapDiv = $('#map'),
                lat = $mapDiv.data('lat'),
                lan = $mapDiv.data('lan'),
                zoom = $mapDiv.data('zoom'),
                marker = $mapDiv.data('marker'),
                title = $mapDiv.data('title'),
                windowHeight = $( window ).height(),
                mapHeight =  (windowHeight/2) - 150;

            //set map height to half window height
            if (mapHeight<400) {
                mapHeight = 400;
            }

            $mapDiv.height(mapHeight);

            if($('body').hasClass('mobile')) {
                var optionNavigationControl = false,
                    optionTypeControl = false,
                    optionScaleControl = false,
                    optionDraggable = false,
                    optionScrollwheel = false;
            }
            else {
                var optionNavigationControl = false,
                    optionTypeControl = true,
                    optionScaleControl = true,
                    optionDraggable = true,
                    optionScrollwheel = false;
            }

            var myLatLng = {lat: lat, lng: lan};
            var map = new google.maps.Map(document.getElementById('map'), {
                zoom: zoom,
                // mapTypeId: google.maps.MapTypeId.SATELLITE,
                navigationControl: optionNavigationControl,
                mapTypeControl: optionTypeControl,
                scaleControl: optionScaleControl,
                draggable: optionDraggable,
                scrollwheel: optionScrollwheel,
                center: myLatLng
            });

            var markerImage = new google.maps.MarkerImage(marker,
                new google.maps.Size(30, 50), //size
                new google.maps.Point(0, 0), //origin point
                new google.maps.Point(15, 50)); // offset point

            var marker = new google.maps.Marker({
                position: myLatLng,
                map: map,
                icon: markerImage,
                title: title
            });
        }

        //Set home slider height
        function setHomeSliderHeight() {
            var width = $( window ).width(),
                height = $( window ).height();

            if(width>769) {
                //large screan slider sizes
                $('.h-slide').height(height-199);
            }
            else {
                if (641<height) {
                    //tablet screen slider sizes
                    $('.h-slide').height(height-210);
                }
                else if (height>width) {
                    //mobile screen portrait
                    $('.h-slide').height(height-210);
                }
                else {
                    if (width>598) {
                        //mobile screen landscape large
                        $('.h-slide').height(500);
                        $('.home-slider').height(500).addClass('landscape');
                    }
                    else {
                        //mobile screen landscape small
                        $('.h-slide').height(400);
                        $('.home-slider').height(400).addClass('landscape');
                    }
                }
            }
        }

        function bindOpacity() {
            $(window).on('scroll', function() {
                $.each($('.animate'), function( index, value ) {
                    if (isElementVisible($(this)) && $(this).hasClass('show')) {
                        var thisH = $(this).height();
                        $(this).removeClass('animationz').addClass($(this).data('animation')).css('min-height', thisH);
                    }
                });
                $('#team').isotope();
            });
        }

        /**
         * More or less team members button click
         */
        function bindTeamMore() {
            $('.v-more.team').on('click', function (e) {
                e.preventDefault();
                var status = $(this).data('status');
                $('html, body').animate({scrollTop: '+=10px'}, 500);

                if (status == 'more') {
                    $(this).data('status', 'less').text('Hide').addClass('arrUp');
                    $('.member-item.more-team').addClass('show');

                }
                else {
                    $(this).data('status', 'more').text('View more').removeClass('arrUp');
                    $('.member-item.more-team').removeClass('show');

                }
                $('#selected').text('filter by').data('sel', '');
                // $('#selected').trigger( "change" );
                //getMembers(status, feed);
                $('.isotope').isotope('destroy'); //destroy existing isotope
                initIsotope(); // init new isotope
            });
        }
        /**
         * Bend function to open single tem member item
         * reInit after loading tem members list via AJAX
         */
        function bindTeamMember() {
            $('.member-item').on('click', function() {
                $('#personInfo .data').empty();
                var id = $(this).attr('id');
                geMemberInfo(id);
            });
        }


        /**
         * Single team member info loading
         * @param id
         */
        var xhr = null;
        function geMemberInfo(id) {
            if( xhr != null ) {
                xhr.abort();
                xhr = null;
            }
            var feed = $('.v-more.team').data('feed')+'/wp-admin/admin-ajax.php';
            xhr = $.ajax({

                url: feed,
                timeout: 3600,
                type: "GET",
                cache: false,

                data: ({
                    action:'person_info',
                    person_id: id
                }),

                beforeSend: function() {
                    // $('#loader').show();
                },

                success: function( data ) {
                    $('#personInfo .data').html(data);
                    $('#personInfo').foundation('open');
                },

                error: function( jqXHR, textStatus, errorThrown ) {

                    console.log( 'The following error occured: ' + textStatus, errorThrown );

                },
                complete: function( jqXHR, textStatus ) {
                }
            });
        }

        //click trigger
        function bindClick() {
            $('.clickTrigger').on('click', function(e) {
                e.preventDefault();
                var targetLine = $(this).data('target');
                var $target = $( targetLine);
                $target.trigger( "click" );

            });
        }

        function imagesQuality() {
          //  console.log('Img quality running');
            if(getWindowWidth()>1366) {
                $('.to-large').each(function() {
                    if($(this).data('large').length>0) {
                        $(this).attr('src', $(this).data('large'));
                    }
                });
            }
            else {
                $('.to-large').each(function() {
                    if($(this).data('large').length>0) {
                        $(this).attr('src', $(this).data('medium'));
                    }
                });
            }
            if(getWindowWidth()>960) {
                $('.bg-to-large').each(function() {
                    if($(this).data('large').length>0) {
                        $(this).attr('style', 'background-image: url('+$(this).data('large')+');');
                       // console.log($(this).data('medium') + ' to ' + $(this).data('large'));
                    }
                });
            }
            else {
                $('.bg-to-large').each(function() {
                    if($(this).data('large').length>0) {
                        $(this).attr('style', 'background-image: url('+$(this).data('medium')+');');
                       // console.log($(this).data('large') + ' to ' + $(this).data('medium'));
                    }
                });
            }
        }

        $('.swap-on-ready').attr('src', $(this).data('src'));

        //function scroll to element
        function scrollToEl(target, offset) {
            var $target = $(target);
            $("html, body").animate({ scrollTop: $target.offset().top - (offset + 70)}, 1000);
        }

        //window height
        function getWindowHeight() {
            return $( window ).height();
        }

        //window width
        function getWindowWidth() {
            return $( window ).width();
        }

        //validate Phone
        function validatePhone(phone) {
            var re = /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{3,4}?[ \-]*[0-9]{3,4}?$/;
            return re.test(phone);
        }

        //validate Email
        function validateEmail(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        }

        //check if element is visible #element, .element
        function isElementVisible(elementToBeChecked) {
            var TopView = $(window).scrollTop();
            var BotView = TopView + $(window).height();
            var TopElement = $(elementToBeChecked).offset().top;
            var BotElement = TopElement + $(elementToBeChecked).height();
            return ((BotElement <= BotView) && (TopElement >= TopView));
        }

        function isElementBottomVisible(elementToBeChecked) {
            var TopView = $(window).scrollTop();
            var BotView = TopView + $(window).height();
            var TopElement = $(elementToBeChecked).offset().top;
            var BotElement = TopElement + $(elementToBeChecked).height()-200;
            return (BotElement <= BotView);
        }

        $(window).scroll(function(){
            if($('body').hasClass('page-template-template-skeleton-php')) {
                if (isElementBottomVisible('.skeleton-links img.animation')) {
                    var image = $('.skeleton-links img.animation'),
                        skeletinLinks = $('.skeleton-links'),
                        delayTime = skeletinLinks.data('delay');
                    if(!$('.skeleton-links img.animation').hasClass('no-visible')) {
                        setTimeout(function(){
                            image.attr('src', image.data('moving')).addClass('no-visible');
                           // $('.skeleton-links img.animation').addClass('not-active');
                          //  $('.skeleton-links img.skeleton').removeClass('not-active');
                        }, delayTime);
                        setTimeout(function(){
                            $('.skeleton-links').removeClass('no-links');
                            $('.skeleton-links img.animation').addClass('no-visible');
                        }, 4000);
                    }
                }
            }
        });

        $('.reset-animation').on('click', function(){
            var image = $('.skeleton-links img.animation'),
                skeletinLinks = $('.skeleton-links');
            skeletinLinks.addClass('no-links');
            image.attr('src', image.data('moving'));
            setTimeout(function(){
                skeletinLinks.removeClass('no-links');
                image.addClass('no-visible');
            }, 4000);

        });

    //Document Ready ENDS
    });
})(jQuery);